import { SecurityContext } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { SafeValue } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';

export function sanitizeInputFactory() {
  return () => {
    // Handle FormControl
    const formControlPrototype = FormControl.prototype as any;
    formControlPrototype._oldSetValue = formControlPrototype.setValue;

    formControlPrototype.setValue = function(value: any, options: any) {
      if (typeof value === 'string'){
        const sanitizedValue: SafeValue | null = DOMPurify.sanitize(
          // SecurityContext.HTML,
          value
        );
        arguments[0] = sanitizedValue ? sanitizedValue.toString() : '';
        if (value.includes('&')) {
          arguments[0]= arguments[0]?.replace(/&amp;/g, '&');
        }
        if (value.includes('"')) {
          arguments[0] = arguments[0]?.replace(/&#34;/g, '"');
        }
        arguments[0] = arguments[0] ? arguments[0].toString() : '';
      }
      return this._oldSetValue(...arguments);
    };

    // Handle NgModel
    const ngModelPrototype = NgModel.prototype as any;
    ngModelPrototype._oldSetValue = ngModelPrototype._updateValue;

    ngModelPrototype._updateValue = function(value: any) {
      // Check if ngModelOptions has standalone set to true
      if (this.options?.standalone) {
        return this._oldSetValue(...arguments);
      }
      
      if (typeof value === 'string'){
        const sanitizedValue: SafeValue | null = DOMPurify.sanitize(
          // SecurityContext.HTML,
          value
        );
        arguments[0] = sanitizedValue ? sanitizedValue.toString() : '';
        if (value.includes('&')) {
          arguments[0]= arguments[0]?.replace(/&amp;/g, '&');
        }
        if (value.includes('"')) {
          arguments[0] = arguments[0]?.replace(/&#34;/g, '"');
        }
        arguments[0] = arguments[0] ? arguments[0].toString() : '';
      }
      return this._oldSetValue(...arguments);
    };
  };
}
